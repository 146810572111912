import { useMemo } from 'react';

const useDarkModeClass = (darkModeValue: string) => {
  const className = useMemo(() => {
    const isDarkMode = darkModeValue?.toLowerCase() == 'true';
    return isDarkMode ? 'darkMode' : '';
  }, [darkModeValue]);
  return className;
};

export default useDarkModeClass;